<template>
  <div class="p-3 bg-gray-relative-100 mh-vh-100-nav">
    <SubHeader title="결제요청"></SubHeader>
    <div class="rounded-3 bg-body shadow-200 mb-4 overflow-hidden">
      <h5 class="fw-bold p-3">예약정보</h5>
      <hr class="m-0 border-gray-relative-400" />
      <h6 class="fw-bold p-3 pb-0">
        <span class="badge bg-primary">매장정보</span>
      </h6>
      <div class="flex-between-center p-3">
        <div class="text-gray-relative-600 flex-between-center w-50 pe-2">
          <span>매장명</span>
          <a :href="site_url" target="_blank"><i class="fas fa-link"></i></a>
        </div>
        <span class="w-50">
          <input
            type="text"
            v-model="location_name"
            class="form-control fw-bold text-end"
          />
        </span>
      </div>

      <!-- <div class="flex-between-center p-3">
                <span class="text-gray-relative-600">연락처</span>
                <span>02-1234-56789</span>
            </div>
            <div class="flex-between-center p-3">
                <span class="text-gray-relative-600">팩스 번호</span>
                <span>02-1234-56789</span>
            </div> -->
      <div class="flex-between-center p-3">
        <span class="text-gray-relative-600">주소</span>
        <span>{{ address }}</span>
      </div>

      <hr class="border-gray-relative-400" />

      <div class="flex-between-center p-3">
        <span class="text-gray-relative-600">결제자명</span>
        <span>{{ user_name }}</span>
      </div>

      <div class="flex-between-center p-3">
        <span class="text-gray-relative-600">예약자명</span>
        <span>{{ name }}</span>
        <!-- <span class="w-50">
          <input
            type="text"
            v-model="name"
            class="form-control fw-bold text-end"
          />
        </span> -->
      </div>
      <div class="flex-between-center p-3" v-if="proxy == 'N'">
        <span class="text-gray-relative-600">라운딩 참석자명</span>
        <span class="w-50">
          <input
            type="text"
            v-model="name"
            class="form-control fw-bold text-end"
          />
        </span>
      </div>

      <div class="flex-between-center p-3" v-if="proxy == 'Y'">
        <span class="text-gray-relative-600">라운딩 참석자명</span>
        <div class="w-50 d-flex flex-column">
          <div
            class="d-flex justify-content-end gap-3"
            v-for="item in proxy_list"
            :key="item.no"
          >
            <span>{{ item.name }}</span>
            <span>{{ phoneFormat(item.tel) }}</span>
          </div>
        </div>
      </div>

      <div class="flex-between-center p-3">
        <span class="text-gray-relative-600">예약자 결제인원</span>
        <span class="w-50 form-control d-flex">
          <input
            type="text"
            v-model="people"
            class="w-100 border-0 fw-bold text-end"
          />명
        </span>
      </div>

      <div class="flex-between-center p-3">
        <span class="text-gray-relative-600">예약 일자</span>
        <span class="w-50">
          <Datepicker :value="e_r_date" @change="ChangeDate" />
        </span>
      </div>

      <div class="flex-between-center p-3" v-if="type == 'R'">
        <span class="text-gray-relative-600">예약 요청 시간</span>
        <span>{{ start_time }} ~ {{ end_time }}</span>
      </div>
      <div class="flex-between-center p-3" v-if="type == 'R'">
        <span class="text-gray-relative-600">최종 예약 시간</span>
        <span>
          <input
            class="form-control"
            type="time"
            id="radio1_1"
            v-model="confirm_time"
          />
        </span>
      </div>

      <div class="flex-between-center p-3" v-if="type == 'D'">
        <span class="text-gray-relative-600">예약 시간</span>
        <span class="w-50">
          <input
            class="form-control"
            type="time"
            id="radio1_1"
            v-model="confirm_time"
          />
        </span>
      </div>

      <div v-if="type == 'D'">
        <div class="flex-between-center p-3">
          <span class="text-gray-relative-600">예약코스</span>
          <span class="w-50">
            <input
              type="text"
              v-model="memo"
              class="form-control fw-bold text-end"
            />
          </span>
        </div>
        <div class="flex-between-center p-3">
          <span class="text-gray-relative-600">예약금액</span>
          <span>{{ CheckPrice(price) }} 원</span>
        </div>
      </div>

      <div class="flex-between-center p-3">
        <span class="text-gray-relative-600">기타 요청 사항</span>
        <span>{{ other_memo }}</span>
      </div>

      <hr class="mb-0 border-gray-relative-400" />

      <h6 class="fw-bold p-3 pb-0">
        <span class="badge bg-primary">결제금액 정보</span>
      </h6>

      <div>
        <div class="d-flex gap-3 p-3">
          <button
            class="btn fs-px-14 btn-outline-main w-33 thick"
            :class="{ 'bg-main text-body-i': greenfee }"
            @click="greenfee = !greenfee"
          >
            그린피
          </button>
          <button
            class="btn fs-px-14 btn-outline-main w-33 thick"
            :class="{ 'bg-main text-body-i': cart }"
            @click="cart = !cart"
          >
            카트비
          </button>
          <button
            class="btn fs-px-14 btn-outline-main w-33 thick"
            :class="{ 'bg-main text-body-i': etc }"
            @click="etc = !etc"
          >
            기타
          </button>
        </div>

        <div class="flex-between-center p-3" v-if="greenfee">
          <span class="text-gray-relative-600">그린피</span>
          <span class="w-50 form-control d-flex">
            <input
              type="text"
              v-model="green_price"
              class="w-100 border-0 fw-bold text-end"
              @keyup="TotalPrice()"
            />원
          </span>
        </div>
        <div class="flex-between-center p-3" v-if="cart">
          <span class="text-gray-relative-600">카트비</span>
          <span class="w-50 form-control d-flex">
            <input
              type="text"
              v-model="cart_price"
              class="w-100 border-0 fw-bold text-end"
              @keyup="TotalPrice()"
            />원
          </span>
        </div>

        <div class="flex-between-center p-3" v-if="etc">
          <span class="text-gray-relative-600">기타</span>
          <span class="w-50 form-control d-flex">
            <input
              type="text"
              v-model="etc_price"
              class="w-100 border-0 fw-bold text-end"
              @keyup="TotalPrice()"
            />원
          </span>
        </div>
      </div>

      <hr class="m-0 border-gray-relative-400" />

      <div class="flex-between-center p-3">
        <span class="text-gray-relative-600">총 주문금액</span>
        <span
          ><b class="fs-5">{{ CheckTotalPrice(total_price) }}</b
          >원</span
        >
      </div>
    </div>

    <div
      @click="PointPaymentAdd()"
      class="rounded-3 btn thick btn-main w-100 mt-4"
    >
      결제요청
    </div>
    <PaymentItemList ref="PaymentItemList" @Select="Select" :list="item_list" />
    <Loading v-if="loading" />
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue';
import PaymentItemList from '@/components/popup/PaymentItemList.vue';
const CryptoJS = require('crypto-js');
import Loading from '@/components/common/Loading.vue';
import Datepicker from '@/components/common/Datepicker.vue';
import { format } from '@/mixins';
const exactMath = require('exact-math');

export default {
  components: {
    SubHeader,
    PaymentItemList,
    Loading,
    Datepicker,
  },
  mixins: [format],
  data() {
    return {
      selected_item_list: [],
      loading: false,
      item_list: [],
      user_name: '',
      location_name: '',
      people: '',
      r_date: '',
      start_time: '',
      end_time: '',
      confirm_time: '',
      code: this.$route.params.code,
      type: '',
      memo: '',
      e_r_date: '',
      price: '',
      greenfee: false,
      cart: false,
      etc: false,
      green_price: '',
      cart_price: '',
      etc_price: '',
      total_price: '',
      site_url: '',
      address: '',
      other_memo: '',
      proxy: '',
      proxy_list: [{ no: 0, name: '', tel: '' }],
    };
  },
  mounted() {
    this.GetReservationInfo();
    this.GetItemList();
  },
  methods: {
    ChangeDate(info) {
      this.e_r_date = info
      const r_date = this.e_r_date;
      const b_r_date = this.r_date;
      this.$confirm(
        `예약일 변경 <br>${b_r_date} -> ${r_date} <br> 변경 하시겠습니까?`
      ).then((result) => {
        if (result.isOk) {
          this.ChangeReservationDate();
        } else {
          this.e_r_date = this.r_date;
        }
      });
    },
    CheckPrice(value) {
      const amount = value * 1;
      return amount.toLocaleString();
    },
    ChangeReservationDate() {
      const r_date = this.e_r_date;
      const code = this.code;
      const body = { code, r_date };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http
        .post('/shop/reservation/ChangeReservationDate', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              this.$alert('예약일을 변경하였습니다.');
            } else if (res.data.code == '9999') {
              this.$store.dispatch('SETLOGOUT').then(() => {
                window.location.href = '/signin';
              });
            }
          }
        });
    },
    CheckTotalPrice() {
      const value = this.total_price;
      const amount = value * 1;
      return amount.toLocaleString();
    },
    TotalPrice() {
      let green_price = this.green_price;
      let cart_price = this.cart_price;
      let etc_price = this.etc_price;

      if (green_price == '') {
        green_price = 0;
      }

      if (cart_price == '') {
        cart_price = 0;
      }

      if (etc_price == '') {
        etc_price = 0;
      }

      const total_price = exactMath.add(green_price, cart_price, etc_price);

      this.total_price = total_price;
    },
    GetReservationInfo() {
      const code = this.code;
      const body = { code };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http
        .post('/shop/reservation/GetReservationInfo', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              const e_body = res.data.body;
              const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
              );
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res);
              const info = body.info;
              const proxyList = body.proxy_list;
              this.location_name = info.location_name;
              this.people = info.people;
              this.user_name = info.user_name;
              this.name = info.name;
              this.type = info.type;
              this.r_date = info.date;
              this.e_r_date = info.date;
              this.start_time = info.start_time;
              this.end_time = info.end_time;
              this.memo = info.memo;
              this.price = info.price;
              this.confirm_time = info.confirm_time;
              this.site_url = info.site_url;
              this.address = info.location_address;
              this.other_memo = info.other_memo;
              this.proxy = info.proxy;
              this.proxy_list = proxyList;
            } else if (res.data.code == '9999') {
              this.$store.dispatch('SETLOGOUT').then(() => {
                window.location.href = '/signin';
              });
            }
          }
        });
    },
    GetItemList() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;

      this.$http.post('/shop/payment/GetItemList', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);
            this.item_list = body.list;
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              window.location.href = '/signin';
            });
          }
        }
      });
    },
    Select(index) {
      this.selected_item_list.push(this.item_list[index]);
    },
    Remove(index) {
      this.selected_item_list.splice(index, 1);
    },
    PointPaymentAdd() {
      const code = this.code;

      let item_list = [];

      if (this.greenfee) {
        const green_price = this.green_price;
        item_list.push({ code: 'GREEN', price: green_price });
      }

      if (this.cart) {
        const cart_price = this.cart_price;
        item_list.push({ code: 'CART', price: cart_price });
      }

      if (this.etc) {
        const etc_price = this.etc_price;
        item_list.push({ code: 'ETC', price: etc_price });
      }

      const confirm_time = this.confirm_time;
      if (item_list.length == 0) {
        this.$alert('결제할 상품을 선택해주세요.');
        return false;
      }
      if (confirm_time == '') {
        this.$alert('최종 예약 시간을 선택해주세요.');
        return false;
      }

      const body = { code, confirm_time, item_list };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http.post('/shop/reservation/PaymentAdd', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            this.$alert('고객에게 결제 요청하였습니다.').then(() => {
              this.$router.replace({ path: `/reservation/confirm` });
            });
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              window.location.href = '/signin';
            });
          }
        }
      });
    },
    CouponPaymentAdd() {
      const item_list = this.selected_item_list;
      const name = this.name;
      const phone = this.phone;
      const type = this.type;

      if (item_list.length == 0) {
        this.$alert('결제할 상품을 선택해주세요.');
        return false;
      }

      if (type == '') {
        this.$alert('결제 방식을 선택해주세요.');
        return false;
      }

      if (name == '') {
        this.$alert('고객 이름을 입력해주세요.');
        return false;
      }

      if (phone == '') {
        this.$alert('고객 휴대폰을 입력해주세요.');
        return false;
      }

      const body = { name, phone, item_list, type };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http.post('/shop/payment/CouponPaymentAdd', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);
            const payment_code = body.code;

            this.$router.push({ path: `/coupon/payment/${payment_code}` });
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              window.location.href = '/signin';
            });
          }
        }
      });
    },
  },
};
</script>

<style></style>
