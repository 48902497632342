<template>
    <!-- 예약팝업 -->
    <div v-show="pp">
        <div class="basic_pp bottom_up" :class="{'off':off}">
            <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
            <div class="content bg-body">
                <div class="header position-sticky flex-between-center pb-3">
                    <h5 class="text-truncate">상품목록</h5>
                    <i class="fal fa-times p-2" @click="pp_off()"></i>
                </div>
                <div class="body overflow-auto pt-1" ref="basic_pp_body">
                    <ul class="last-border-0">
                        <li class="border-bottom border-gray-relative-200 py-2 d-none d-sm-block" v-for="(item,index) in list" :key="`s_${index}`" >
                            <div @click="Select(index)" class="flex-between-center py-2" v-if="item.type=='D'">
                                <div class="txt_box text-start flex-grow-1 small flex-between-center">
                                    <div class="fw-500 lh-sm">{{item.name}}1</div>
                                    <div class="fw-600 lh-sm flex-shrink-0 ms-3">{{item.price.toLocaleString()}}<small>원</small></div>
                                </div>
                            </div>
                            <div  class="flex-between-center py-2" v-if="item.type=='E'">
                                <div class="txt_box text-start flex-grow-1 small flex-between-center">
                                    <div class="fw-500 lh-sm">{{item.name}}</div>
                                    <div class="fw-600 lh-sm flex-shrink-0 ms-3">
                                        <div class="d-flex">
                                            <input type="text" v-model="item.price" class="form-control"  @input="inputOnlyNumberFormat(index);">
                                            <button class="btn btn-success ms-3" style="width:100px" @click="Select(index)">확인</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom border-gray-relative-200 py-2 d-block d-sm-none d-md-none" v-for="(item,index) in list"  :key="`m_${index}`" >
                            <div @click="Select(index)" class="flex-between-center py-2" v-if="item.type=='D'">
                                <div class="txt_box text-start flex-grow-1 small">
                                    <div class="fw-500 lh-sm">{{item.name}}</div>
                                    <div class="fw-600 lh-sm flex-shrink-0">{{item.price.toLocaleString()}}<small>원</small></div>
                                </div>
                            </div>
                            <div  class="flex-between-center py-2" v-if="item.type=='E'">
                                <div class="txt_box text-start flex-grow-1 small">
                                    <div class="fw-500 lh-sm">{{item.name}}</div>
                                    <div class="fw-600 lh-sm flex-shrink-0">
                                        <div class="d-flex">
                                            <input type="text" v-model="item.price" class="form-control"  @input="inputOnlyNumberFormat(index);">
                                            <button class="btn btn-success ms-3" style="width:100px" @click="Select(index)">확인</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props:{
        list: {
            type: Array,
            default: [],
        },
    },
    data(){
        return{

            // front ui
			pp: false,
			off: false,
            timeout_id: null,

        }
    },
    methods:{
        CheckShow(info){
            if(info !=''){
                const price = info *1;
                return price.toLocaleString();
            }
        },
        comma(str) {
            str = String(str);
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        uncomma(str) {
            str = String(str);
            return str.replace(/[^\d]+/g, '');
        },
        inputOnlyNumberFormat(index) {
            const price = this.list[index].price;
            this.list[index].price = this.comma(this.onlynumber(this.uncomma(price)));
        },
        
        onlynumber(str) {
            str = String(str);
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g,'$1');
        },
        Select(index){
            this.list[index].price = this.uncomma(this.list[index].price)*1;
            this.$emit( "Select", index);
            this.pp_off();
        },
        // front ui
        pp_off(){
            this.off = true;

            if (this.timeout_id) {
                clearTimeout(this.timeout_id);
            }

            this.timeout_id = setTimeout(() => {
                this.pp = false;
                this.off = false;
                if( this.$refs.basic_pp_body ){
                    this.$refs.basic_pp_body.scrollTop = 0;
                }
                this.timeout_id = null;
            }, 200);
        }
    }

}
</script>
<style lang="scss" scoped>
    .dark{
        .border-gray-blur-pp{ border-color: #555555 !important; }
        .icon_box .icon_bg{
            opacity: 0.7;
        }
    }
    .light{
        .border-gray-blur-pp{ border-color: #d7d7d7 !important; }
    }
</style>