import moment from 'moment';
moment.locale('ko');

export const format = {
  methods: {
    addDay(date) {
      if (!date) return;

      const day = moment(date).format('dddd')[0];
      let format = `${date}(${day})`;

      if (date.includes(' ')) {
        format = `${date.split(' ')[0]}(${day}) ${date.split(' ')[1]}`;
      }
      return format;
    },
    phoneFormat(phone) {
      return phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    },
  },
};
